import React from 'react';
import './users.css';
import User from './user';
import Modal from '../modal/modal';

class UserList extends React.Component{
    constructor(props){
        super(props);
        this.deleteUser = this.deleteUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.hideCurrentModal = this.hideCurrentModal.bind(this);
        this.updateFormElement = this.updateFormElement.bind(this);
        this.addUserData = this.addUserData.bind(this);
        this.clearLinkDataForUserStart = this.clearLinkDataForUserStart.bind(this);
        this.clearLinkDataForUserConfirm = this.clearLinkDataForUserConfirm.bind(this);
        this.state = {
            users: [],
            modalDisplay: null,
            form: {
                name: '',
                email: '',
                password1: '',
                password2: ''
            },
            passwordError: '',
            lastAddedUser: null,
            userIndexToDelete: null
        }
    }
    componentDidMount(){
        this.getCurrentUsers();
    }
    getCurrentUsers(lastAddedUser){
        fetch('/api/getusers.php',{
            headers: {
                'authorization': this.props.token
            }
        })
            .then( response => response.json() )
            .then( response => {
                if(response.success){
                    const newState = {
                        users: response.data
                    }
                    if(lastAddedUser){
                        newState['lastAddedUser'] = lastAddedUser
                    }
                    this.setState(newState)
                }

            })
    }
    deleteUser(id){
        fetch('/api/deleteuser.php',{
            method: 'post',
            headers: {
                authorization: this.props.token
            },
            body: JSON.stringify({ 
                id: id
            })
        })
            .then( response=>response.json())
            .then( response => {
                if(response.success){
                    this.setState({
                        modalDisplay: null
                    })
                    this.getCurrentUsers(response.id);
                } else {
                    alert(response.response)
                }
                
            })
    }
    addUser(){
        this.setState({
            modalDisplay: 'createUser'
        })
    }
    clearLinkDataForUserStart(index){
        this.setState({
            modalDisplay: 'confirmLinkDataClear',
            userIndexToDelete: index
        })
    }
    clearLinkDataForUserConfirm(index){
        fetch('/api/deleteuserlinkdata.php',{
            method: 'post',
            headers: {
                authorization: this.props.token
            },
            body: JSON.stringify({ 
                id: this.state.users[index].id
            })
        })
            .then( response=>response.json())
            .then( response => {
                this.setState({
                    modalDisplay: null
                })
                if(response.success){
                    this.getCurrentUsers(response.id);
                } else {
                    alert(response.response)
                }
                
            })        
    }
    hideCurrentModal(){
        this.setState({
            modalDisplay: null
        })
    }
    updateFormElement(event){
        const target = event.target;
        const value = target.value;
        const name = target.getAttribute('name');
        const formValues = {...this.state.form};
        formValues[name] = value;
        const outputState = {
            form: formValues
        }
        if(name==='password1' || name==='password2' ){
            const target2 = name==='password1' ? this.state.form.password2 : this.state.form.password1;
            if(target2!==value){
                outputState['passwordError']='passwords must match'
            } else {
                outputState['passwordError']=''
            }
        }
        this.setState(outputState);
    }
    addUserData(){
        fetch('/api/adduser.php',{
            method: 'post',
            headers: {
                authorization: this.props.token
            },
            body: JSON.stringify({ 
                name: this.state.form.name, 
                email: this.state.form.email,
                password: this.state.form.password1
            })
        })
            .then( response=>response.json())
            .then( response => {
                if(response.success){
                    this.setState({
                        modalDisplay: null
                    })
                    this.getCurrentUsers(response.id);
                } else {
                    alert(response.response)
                }
                
            })
    }
    render(){
        if(this.state.users.length<1){
            return <div>loading...</div>;
        }
        let modal = null
        switch(this.state.modalDisplay){
            case 'createUser':
                modal = (
                    <Modal display={true} onClose={this.hideCurrentModal}>
                        <input type="text" onChange={this.updateFormElement} value={this.state.form.name} name="name" placeholder="name"/>
                        <input type="text" onChange={this.updateFormElement} value={this.state.form.email} name="email" placeholder="email"/>
                        <input type="password" onChange={this.updateFormElement} value={this.state.form.password1} name="password1" placeholder="password"/>
                        <input type="password" onChange={this.updateFormElement} value={this.state.form.password2} name="password2" placeholder="password again"/>
                        <div className="passwordError">{this.state.passwordError}</div>
                        <button type="button" onClick={this.addUserData}>Add</button>
                    </Modal>  
                );
                break;
            case 'confirmLinkDataClear':
                modal = (
                    <Modal display={true} onClose={this.hideCurrentModal}>
                        You are about to clear the linkdata for this user.<br/>
                        {this.state.users[this.state.userIndexToDelete].name}<br/>
                        This cannot be undone.<br/>
                        Are you sure?<br/>
                        <button type="button" onClick={()=>this.clearLinkDataForUserConfirm(this.state.userIndexToDelete)}>yes, Clear linkdata</button> <button type="button" onClick={this.hideCurrentModal}>no, cancel</button>
                    </Modal>  
                    );
                    break;
        }
        return (
            <div className="userListContainer">
                <h1>Current users</h1>
                <div className="userList">
                    { this.state.users.map( (user,index) => <User highlight={this.state.lastAddedUser==user.id} deleteUser={this.deleteUser} clearUserLinkData={()=>this.clearLinkDataForUserStart(index)}key={user.id} data={user}/>) }
                </div>
                <div className="genericActions">
                    <button type="button" onClick={this.addUser}>Create user</button>
                </div>
                {modal} 
            </div>
        )
    }
}

export default UserList;