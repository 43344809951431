import React from 'react';
import './modal.css';

export default (props)=>{
    return(
        <div className={`modal shadow ${props.display?'':'hidden'}`}>
            <div className="body">
                <div className="close" onClick={props.onClose}>X</div>
                <div className="contents">
                    {props.children}
                </div>
            </div>
        </div>
    );
}