import React from 'react';
import logo from './logo.svg';
import './App.css';
import csv from 'csvtojson'
import fieldList from './components/fieldLists';
import FieldData from './components/fieldData';
import TargetField from './components/targetField';

import ToolTip from './components/tooltip/tooltip';

import Modal from './components/modal/modal';
import TableDataDisplay from './components/tableDataDisplay/tableDataDisplay';

import UserList from './components/users/userlist';

class App extends React.Component{
  constructor(props){
    super(props);
    this.updateFormElement = this.updateFormElement.bind( this );
    this.performLogin = this.performLogin.bind(this);
    this.selectFile = this.selectFile.bind( this );
    this.startProcessFile = this.startProcessFile.bind( this );
    this.onItemClick = this.onItemClick.bind( this );
    this.onUploadedDataClick = this.onUploadedDataClick.bind(this);
    this.addLinkToTarget = this.addLinkToTarget.bind(this);
    this.removeLinkFromTarget = this.removeLinkFromTarget.bind(this);
    this.updateHint = this.updateHint.bind(this);
    this.closeRemovalWarning = this.closeRemovalWarning.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkForMissing = this.checkForMissing.bind(this);
    this.downloadFilteredData = this.downloadFilteredData.bind(this);
    this.resetToBeginning = this.resetToBeginning.bind(this);
    this.createLinkedData = this.createLinkedData.bind(this);
    this.showInvalidData = this.showInvalidData.bind(this);
    this.closeInvalidModal = this.closeInvalidModal.bind(this);
    this.showArbitraryModal = this.showArbitraryModal.bind(this);
    this.handleFieldAddConflict = this.handleFieldAddConflict.bind(this);
    this.combineFields = this.combineFields.bind(this);
    this.toggleAddressConversion = this.toggleAddressConversion.bind(this);
    this.removeLinksFromTitleTarget = this.removeLinksFromTitleTarget.bind(this)
    this.saveCurrentLinkData = this.saveCurrentLinkData.bind(this);

    this.state = {
      view: 'login',
      uploadedData: null,
      selectedUploadTitle: null,
      selectedDestinationTitle: null,
      fieldList: {...fieldList},
      headerMap: {},
      linkedData: [],
      valueMap: {},
      form: {
        email: '',//'daniel.paschal@gmail.com',
        password: '',//'fluffybunnies',
        file: null
      },
      fileSelected: false,
      currentIndex: 0,
      hintIndex: -1,
      modalDisplay: null,
      modalAdditionalContent: null,
      specialModes: {
        addressToPieces: false
      },
      hideSelectedFields: false,
      userData: {}
    }
    //console.log("wahhoo:" + this.processCSV(`"dude, thing", "last, first", haha, 4`));
  }
  checkRights(right){
    const rightList = {
      'UPLOAD':1,
      'SAVEPATTERN':2,
      'SEEUSERS':4,
      'ADDUSERS':8,
      'DELETEUSERS':16,
      'RESERVED1':32,
      'RESERVED2':64,
      'SUPERUSER':128      
    }
    if(rightList.hasOwnProperty(right)){
      return (rightList[right] & this.state.userData.data.rights) > 0
    }
    console.error('invalid right ' + right);
    return false;
  }
  updateFormElement(event){
    const target = event.target;
    const value = target.value;
    const name = target.getAttribute('name');
    const formValues = {...this.state.form};
    formValues[name] = value;
    this.setState({
      form: formValues
    });
  }
  updateHint(index){
    return;
    if(index){
      this.setState({
        hintIndex: index+1
      })
    } else {
      this.setState({
        hintIndex: this.state.hintIndex+1
      })
    }
    console.log('updating', index);
    // let newHintIndex = this.state.hintIndex+1;
    // if(this.state.hintClasses[newHintIndex]!==undefined){
    //   this.setState({
    //     hintIndex: newHintIndex
    //   })    
    //}

  }
  performLogin(){
    fetch('/api/logon.php',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.form.email,
        password: this.state.form.password
      })
    })
      .then( response => response.json() )
      .then( data => {
        if(data.success){
          console.log('response: ',data)
          this.setState({
            view: 'upload',
            userData: data
          });
          /*rights:
            0:1 can upload data
            1:2 can save links
            2:4 can see users
            3:8 can add lower users
            4:16 can delete all users
          */
          this.updateHint(0);
        } else {
          alert( data.response)
        }

      })
      .catch((response)=>{
        alert(response)
      })

  }
  processCSV( source ){
    let quoteStart = false;
    let destination = '';
    for( let sourcePos = 0; sourcePos < source.length; sourcePos++){
      let letter = source[sourcePos];
      if(letter === `'` || letter === `"`){
        if(quoteStart===false){
          quoteStart = true;
        } else {
          quoteStart = false;
        }
        destination += letter;
      } else if(letter===',' && quoteStart){
          destination += '~*~';
      } else {
        destination += letter;
      }
    }
    return destination;   
  }
  onItemClick(e){
    let direction = 1;
    if(e.nativeEvent.shiftKey){
      direction = -1;
    }
    let nextIndex = this.state.currentIndex+direction;
    if(nextIndex === this.state.uploadedData.data.length){
      nextIndex = 0;
    } else if(nextIndex < 0){
      nextIndex = this.state.uploadedData.data.length-1;
    }
    this.setState( {currentIndex: nextIndex });
  }
  onUploadedDataClick(event, title){
    if(this.state.selectedUploadTitle===title){
      title=null;
    } else {
      //console.log('not the same title)
    }
    this.setState({
      selectedUploadTitle: title
    })
  }
  findItemInHeaders(item){
    for( let index = 0; index < this.state.uploadedData.headers.length; index++){
      if(this.state.uploadedData.headers[index].title===item){
        return index;
      }
    }
    return false;
  }
  processLinkData(linkData){
    for(let key in linkData){
      let links = linkData[key];
      links.forEach( item => {
        this.addLinkToTarget(item, key)
      })
    }
  }
  addLinkToTarget(event,title){
    let selectedUploadTitle;
    if(typeof event === 'string'){
      selectedUploadTitle = event;
    } else {
      selectedUploadTitle = this.state.selectedUploadTitle;
    }
    if(selectedUploadTitle!==null){
      const targetSection = {...this.state.fieldList};
      if(typeof targetSection.originalSource[title] === 'boolean'){
        targetSection.originalSource[title] = {
          mandatory: targetSection.originalSource[title],
          fieldNameRegex: null,
          fieldValueRegex: null
        }
      };
      if(!targetSection.originalSource[title].processChosen && typeof targetSection.originalSource[title].fieldValueRegex === 'function' ){
        this.setState({
          selectedDestinationTitle: title
        })
        let fieldIndex = this.state.headerMap[selectedUploadTitle];
        let fieldValue = this.state.uploadedData.data[0][fieldIndex];
        // if(targetSection.originalSource[title].fieldValueRegex(fieldValue, targetSection.originalSource, this.showArbitraryModal, this.toggleZipAddressConversion)){
        //   return;
        // }
        
        //the system has a check for the value and likely needs to answer a question, so return out to pause this
      }
      if(targetSection.originalSource[title].linkedTo && targetSection.originalSource[title].linkedTo.length>0){
        let linkedToCopy = targetSection.originalSource[title].linkedTo.slice();
        linkedToCopy.push(selectedUploadTitle);
        targetSection.originalSource[title].linkedTo = linkedToCopy;
      } else {
        targetSection.originalSource[title].linkedTo = [selectedUploadTitle];
      }
      //console.log("TEST", targetSection);
      const uploadedDataIndex = this.findItemInHeaders(selectedUploadTitle);
      const uploadedHeadersCopy = this.state.uploadedData.headers.slice();
      if(uploadedHeadersCopy[uploadedDataIndex].linkedTo && uploadedHeadersCopy[uploadedDataIndex].linkedTo.length>0){
        let linkedToCopy = uploadedHeadersCopy[uploadedDataIndex].linkedTo;
        linkedToCopy.push(targetSection.originalSource[title]);
        uploadedHeadersCopy[uploadedDataIndex].linkedTo = linkedToCopy;
      } else {
        uploadedHeadersCopy[uploadedDataIndex].linkedTo = [targetSection.originalSource[title]];
      }
      this.setState({
        selectedUploadTitle: null,
        uploadedData: {
          headers: uploadedHeadersCopy,
          data: this.state.uploadedData.data
        },
        modalDisplay: null
      }, ()=>{
        console.log(this.state)
      })
      console.log('linking')
    }
  }
  combineFields(originalTarget){
    // console.log('test', this.state)
    // const originalSourceData = {...this.state.fieldData.originalSource};
    // const originalDestination = this.state.selectedDestinationTitle;
    // const originalDestinationIndex = this.state.headerMap[originalDestination];
    // const destinationCurrentLinkedTo = this.state.uploadedData.headers[originalDestinationIndex];
    // const 
    // let currentLinkedTo = originalSourceData[]
    // const linkedArray = [
    //   this.state.fieldData.originalSource[this.state.selectedUploadTitle].linkedTo,

    // ]
    // this.state.fieldData.originalSource[title].linkedTo = this.state.selectedUploadTitle;
    
  }
  createLinkedData(mode='filtered'){ //all, filtered, excluded 
    const fieldHeaderLabelsMap = {};
    //grab list of destination fields
    const fieldHeaderLabels = [];
    for(let fieldName in this.state.fieldList.originalSource){
      let currentField = this.state.fieldList.originalSource[fieldName];
      if(currentField.linkedTo && currentField.linkedTo.length>0){
        fieldHeaderLabels.push(fieldName);
        //fieldsToGrab.push(currentField.linkedTo);
        fieldHeaderLabelsMap[fieldName] = fieldHeaderLabels.length-1;
      }
    }
    const outputData = this.state.uploadedData.data.map( data => {
      return fieldHeaderLabels.map( fieldName=> {
        let fieldMetaData = this.state.fieldList.originalSource[fieldName];
        let fieldLinkedTo = fieldMetaData.linkedTo;
        let fieldDataValue = '';
        for(let linkIndex = 0; linkIndex < fieldLinkedTo.length; linkIndex++){
          let sourceName = fieldLinkedTo[linkIndex];
          let sourceIndex = this.state.headerMap[sourceName];
          let sourceValue = data[sourceIndex];
          fieldDataValue += ` ${sourceValue}`;

        }
        fieldDataValue = fieldDataValue.trim();
        return fieldDataValue;
      })
    })

    const validData = [];
    const failedData = [];
    const requiredFields = [];
    const addressPartsMap = {};
    const addressValueMap = {};
    const addressParts = ['address','city','state','zip'];
    const coborrowerMap = {};
    let coborrowerMapProcessed = false;
    //loop through keys and see if they have links
      //include in selection if yes
    //if current key in columns below matches a key we are looking for, add to current address
    //at end of loop, see if current key exists in address map
      //if no, add row to data, add address to address map. address map points to row that has this address
      //if yes, don't add row to data, add appropriate fields to originalSource coborrower
    addressParts.forEach( part =>{
      if(this.state.fieldList.originalSource){
        addressPartsMap[part]=true;
      }
    })
    if(mode!=='all'){
       for( let row = 0; row < outputData.length; row++){
        let errorMessages = [];
        let valid = true;
        let addressHash = '';
        for( let column = 0; column < outputData[row].length; column++){
          let columnTitle = fieldHeaderLabels[column];
          let columnData = this.state.fieldList.originalSource[columnTitle];
          if(addressPartsMap.hasOwnProperty(columnTitle)){
            addressHash += outputData[row][column];
          }
          //TODOWORK make this more efficient
          //WEE
          requiredFields[column] = columnData.mandatory;

          if(columnData.mandatory){
            let testRegex = /.+/;//columnData.fieldValueRegex;
            if(!testRegex.test(outputData[row][column])){
              if(!columnData.altTest || !columnData.altTest(outputData[row], fieldHeaderLabels, this.state)){
                valid = false;
                errorMessages.push(`${columnTitle} was invalid`);
              }
              //TODOWORK
            }
            //&& !outputData[row][column]
          }
        }
        if(addressValueMap.hasOwnProperty(addressHash)){
          if(!coborrowerMapProcessed){
            coborrowerMapProcessed = true;
            for(let key in this.state.fieldList.originalSource){
              if(this.state.fieldList.originalSource[key].hasOwnProperty('coborrower')){
                let coborrowerSourceFieldCandidate = this.state.fieldList.originalSource[key].coborrower;
                let coborrowerSourceField = coborrowerSourceFieldCandidate;
                if(Array.isArray(coborrowerSourceFieldCandidate)){
                  coborrowerSourceField = coborrowerSourceFieldCandidate[0];
                  for(let coborrowerKey in coborrowerSourceFieldCandidate){
                    let coborrowerFieldName = coborrowerSourceFieldCandidate[coborrowerKey];
                    if(fieldHeaderLabelsMap.hasOwnProperty(coborrowerFieldName) && outputData[row][fieldHeaderLabelsMap[coborrowerFieldName]]!==undefined){
                      coborrowerSourceField = coborrowerSourceFieldCandidate[coborrowerKey];
                      break;
                    }
                  }
                } 
                if(fieldHeaderLabelsMap[coborrowerSourceField] !== undefined){
                  coborrowerMap[key] = {
                    key: key,
                    origin: fieldHeaderLabelsMap[coborrowerSourceField],
                    destination: fieldHeaderLabels.length,
                    originValue: outputData[row][fieldHeaderLabelsMap[coborrowerSourceField]]
                  }
                  fieldHeaderLabels.push(key);
                  if(fieldHeaderLabelsMap[key]===undefined){
                    fieldHeaderLabelsMap[key] = fieldHeaderLabels.length-1;
                  }
                }

              }
            }
          }
          let mainItemIndex = addressValueMap[addressHash];
          let tempRow = outputData.splice(row,1)[0];
          //check if the current row has enough values.  If not, pad it to the deswired amount
          if(outputData[mainItemIndex].length!==fieldHeaderLabels.length){
            let requiredPadding = fieldHeaderLabels.length - outputData[mainItemIndex].length;
            let paddingArray = new Array(requiredPadding)
            outputData[mainItemIndex].splice(outputData[mainItemIndex].length,0,...paddingArray);
          }
          //copy the values to their coborrower value
          for( let key in coborrowerMap){
            let coborrowerField = coborrowerMap[key];
            outputData[mainItemIndex][coborrowerField.destination] = tempRow[coborrowerField.origin];
          }

        } else if(addressHash){
          addressValueMap[addressHash] = row;
        }
        if(valid){
          validData.push(outputData[row]);
        } else {
          let invalidRow = outputData[row].slice();
          invalidRow.push(errorMessages.join(', '));
          failedData.push(invalidRow);
        }
      }
    }
    for(let index in outputData){
      if(outputData[index].length!==fieldHeaderLabels.length){
        let requiredPadding = fieldHeaderLabels.length - outputData[index].length;
        let paddingArray = new Array(requiredPadding)
        outputData[index].splice(outputData[index].length,0,...paddingArray);
      }
    }

    switch(mode){
      case 'all':
        outputData.unshift(fieldHeaderLabels);
        return outputData;
      case 'excluded':
        fieldHeaderLabels.push('errors');
        failedData.unshift(fieldHeaderLabels);
        failedData.unshift(requiredFields);
        return failedData;
      case 'filtered':
        //process househappy data
        let househappyFields = Object.keys(this.state.fieldList.househappy);
        let househappyData = [];
        for( let index in validData){
          let househappyRow = [];
          for( let field in this.state.fieldList.househappy){
            let fieldResult = this.state.fieldList.househappy[field](validData[index], fieldHeaderLabelsMap);
            househappyRow.push(fieldResult);
          }
          househappyData.push(househappyRow);
        }
        
        househappyData.unshift(househappyFields);

        let bpdFields = Object.keys(this.state.fieldList.bpd);
        let bpdData = [];
        for( let index in validData){
          let bpdRow = [];
          for( let field in this.state.fieldList.bpd){
            let fieldResult = this.state.fieldList.bpd[field](validData[index], fieldHeaderLabelsMap);
            bpdRow.push(fieldResult);
          }
          bpdData.push(bpdRow);
        }

        bpdData.unshift(bpdFields);

        let homebotFields = Object.keys(this.state.fieldList.homebot);
        let homebotData = [];
        for( let index in validData){
          let homebotRow = [];
          for( let field in this.state.fieldList.homebot){
            let fieldResult = this.state.fieldList.homebot[field](validData[index], fieldHeaderLabelsMap);
            homebotRow.push(fieldResult);
          }
          homebotData.push(homebotRow);
        }

        homebotData.unshift(homebotFields);

        validData.unshift(fieldHeaderLabels);
        return {
          originalSource: validData,
          bpd: bpdData,
          houseHappy: househappyData,
          homebot: homebotData
        };
    }
  }
  removeLinkFromTarget(event, title, linkData){
    console.log('removing title ' , linkData)
    const uploadedDataIndex = this.findItemInHeaders(linkData);
    const uploadedHeadersCopy = this.state.uploadedData.headers.slice();
    const fieldListCopy = {...this.state.fieldList};
    fieldListCopy.originalSource[title].linkedTo = [];
    uploadedHeadersCopy[uploadedDataIndex].linkedTo = [];
    this.setState({
      uploadedData: {
        headers: uploadedHeadersCopy,
        data: this.state.uploadedData.data
      },
      fieldList: fieldListCopy
    })
  }
  removeLinksFromTitleTarget(event, title){
    console.log(this.state);
    let linksToRemove = this.state.fieldList.originalSource[title].linkedTo.slice();
    const headerCopy = this.state.uploadedData.headers.slice();
    linksToRemove.forEach( fieldName => {
      let fieldIndex = this.state.headerMap[fieldName];
      let thisFieldMetaData = {...headerCopy[fieldIndex]};
      let fieldLinkArray = thisFieldMetaData.linkedTo=[];
      headerCopy[fieldIndex] = thisFieldMetaData
    });
    const fieldListCopy = {...this.state.fieldList};
    fieldListCopy.originalSource[title].linkedTo = [];
    this.setState({
      uploadedData: {
        data: this.state.uploadedData.data,
        headers: headerCopy
      },
      fieldList: fieldListCopy,
      modalDisplay: null
    })
  }
  handleFieldAddConflict(event, title, linkData){
    //if(this.state.selectedDestinationTitle)
    this.setState({
      selectedDestinationTitle: title,
      modalDisplay: 'destinationTitleConflict',
      modalAdditionalContent: <div>
          <div>The field <span className="fieldName">{title}</span> has an attached fieldlist of <span className="fieldName">{linkData.join(', ')}</span></div>
          Do you want to:<br/>
          <button onClick={(e)=>this.removeLinksFromTitleTarget(e,title)}>Clear all fields from '{title}'</button><br/>
          { this.state.selectedUploadTitle ? (<div><button onClick={(e)=>this.addLinkToTarget(e,title)}>Add '{this.state.selectedUploadTitle}' to '{title}'</button></div>) : null}
          <button onClick={this.closeModal}>Do nothing (cancel)</button><br/>
        </div>
    })
    return;
  }
  startProcessFile(){
    this.setState({
      view: 'process'
    })
    const reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = ((theFile)=> {
      return (e)=> {
        const data = e.target.result
          csv({noheader:true,output:'csv'})
          .on('done', (error)=> {
            if(!error){

            }
          })
          .fromString(data)
          .then( rowData => {
            const rawHeaders = rowData.shift();
            const filteredData = rowData.filter( item => item.join('')!=='');
            const headerMap = {};
            const headers = rawHeaders.map( (item,index) => {
              headerMap[item] = index;
              return {
                title: item,
                linkedTo: []
              }
            })
            this.setState({
              headerMap,
              uploadedData: {
                headers,
                data: filteredData,
              }
            })
            this.updateHint();
            if(this.state.userData.data.linkData){
              this.processLinkData(JSON.parse(this.state.userData.data.linkData))
            }
          })
   
      };
    })(this.state.form.file);

      // Read in the image file as a data URL.
    reader.readAsText(this.state.form.file);
  }
  selectFile(event){
    const currentForm = {...this.state.form};
    currentForm.file = event.target.files[0];
    this.setState({
      form: currentForm,
      fileSelected: true
    })
    this.updateHint();
  }
  checkForMissing(){
    for(let key in this.state.fieldList.originalSource){
      let dataRules = this.state.fieldList.originalSource[key];
      if(dataRules.mandatory && (dataRules.linkedTo===undefined  || dataRules.linkedTo.length===0)){
        //TODOWORK UNCOMMENT THESE TWO LINES FOR PRODUCTION
        // this.showArbitraryModal('downloadIncomplete', key);
        // return;
      }
    }
    // console.log(this.state);
    if(this.createLinkedData('excluded').length>1){
      this.setState({modalDisplay:'removal'})
    }
  }
  saveCurrentLinkData(){
    const originalSource = this.state.fieldList.originalSource;
    const output = {};
    for(let key in originalSource){
      output[key] = originalSource[key].linkedTo
    }
    this.setState({
      modalDisplay: 'saving',
      modalAdditionalContent: <div>saving...</div>
    });
    fetch('/api/savelinkdata.php',{
      method: 'post',
      headers: {
        authorization: this.state.userData.data.token
      },
      body: JSON.stringify({ 
          id: this.state.userData.data.id,
          linkData: output
      })
    })
      .then( response=>response.json())
      .then( response => {
          if(response.success){
              this.setState({
                  modalDisplay: 'saving',
                  modalAdditionalContent: <div>saved!<br/>
                    <button onClick={this.closeModal}>Back to interface</button>
                  </div>
              })
          } else {
              this.setState({
                modalDisplay: null
              })
              alert(response.response)
          }
      })
    
  }

  closeRemovalWarning(){
    this.setState({
      modalDisplay: 'download'
    })
  }
  closeModal(){
    this.setState({
      modalDisplay: null
    })
  }
  downloadFilteredData(){
    this.setState({
      modalDisplay: 'download'
    })
  }
  resetToBeginning(){
    //TODO WORK do this in a better way
    window.location = window.location;
  }
  showInvalidData(){
    this.setState({
      modalDisplay: 'invalidDisplay'
    })    
  }
  closeInvalidModal(){
    this.setState({
      modalDisplay: 'removal'
    })    
  }
  showArbitraryModal(modalToDisplay, additionalContent=null){
    this.setState({
      modalDisplay: modalToDisplay,
      modalAdditionalContent: additionalContent
    })
  }
  toggleAddressConversion(){
    //at this point I know I'm horribly abusing the state engine.  really should have used context or hooks or redux
    //next time
    let valueMap = {...this.state.valueMap};
    const fieldsToToggle = ['zip','state','address','city'];
    const headerIndex = this.state.headerMap[this.state.selectedUploadTitle];
    const updloadedDataPointerLinkToArray = this.state.uploadedData.headers[headerIndex].linkedTo.slice();
    const uploadedData = {...this.state.uploadedData};
    const targetSection = {...this.state.fieldList};
    fieldsToToggle.forEach( field => {
      updloadedDataPointerLinkToArray.push(field);
      if(valueMap[field]){
        valueMap[field] = false;
        targetSection.originalSource[field].processChosen = true;

      } else {
        valueMap[field] = this.state.fieldList.originalSource[field].getPieceFromAddress;
      }
    })
    uploadedData.headers[headerIndex].linkedTo = updloadedDataPointerLinkToArray;

    this.setState({ 
      uploadedData,
      valueMap,
      modalDisplay: null,
      selectedUploadTitle: null
    })
  }
  zeroPadValue(value, length=2){
    let currentLength = (value+'').length
    if(currentLength<length){
      return '0'.repeat((length-currentLength)) + value;
    }
    return value;
  }
  getCurrentDateFormatted(){
    const currentDate = new Date();
    let output = `${currentDate.getFullYear()}-${this.zeroPadValue(currentDate.getMonth())}-${this.zeroPadValue(currentDate.getDate())} ${this.zeroPadValue(currentDate.getHours())}:${this.zeroPadValue(currentDate.getMinutes())}`;
    return output;
  }

  // convertDataToCSV(data){
  //   let output = '';
  //   let lines = [
  //     Object.keys(data).join(',')
  //   ]
  //   lines = lines.concat( Object.values)
  //   output+=
  // }

  //**************** VIEWS ********************/
  view_process(){
    let sourceElements = [];
    if(this.state.uploadedData===null){
      return(<div>processing...</div>)
    } else {
        sourceElements = this.state.uploadedData.headers
        .filter( item => (!this.state.hideSelectedFields || item.linkedTo.length===0))
        .map( (item, index)=>{
          return <FieldData 
                  onClickHandler={this.onItemClick} 
                  onSelectHandler={this.onUploadedDataClick}
                  key={index} title={item.title} 
                  data={this.state.uploadedData.data[this.state.currentIndex][this.state.headerMap[item.title]]}
                  selected={this.state.selectedUploadTitle===item.title}
                  used={item.linkedTo.length>0}
                />
      })
      const originalSourceElements = [];
      let fieldHintShown= false, targetHintshown = false;
      //console.log('mapping')
      for( let key in this.state.fieldList.originalSource){
        //console.log(`${key}: ${this.state.fieldList.originalSource[key].linkedTo}`)
        originalSourceElements.push(<TargetField 
                key={key} 
                title={key} 
                example={this.state.fieldList.originalSource[key].hasOwnProperty('example') ? this.state.fieldList.originalSource[key] : ''}
                required={this.state.fieldList.originalSource[key].hasOwnProperty('mandatory') ? this.state.fieldList.originalSource[key].mandatory : false }
                onAddHandler={this.addLinkToTarget}
                onClickWhileFilled={this.handleFieldAddConflict}
                linkData={this.state.fieldList.originalSource[key].linkedTo}
              />);
      }
      console.log("hint index : " + this.state.hintIndex)      

      return (
        <div className={`fieldAreas`}>
          <div className="fieldContainer uploaded">
          <ToolTip show={this.state.hintIndex===90} index={90} closeHandler={this.updateHint}>
            <div className="fieldScroll">
              {
                sourceElements
              }
            </div>
            <div className="hint">click grey bar to select</div>
          </ToolTip>

          </div>
          <div className="fieldContainer originalSource">
            {
              originalSourceElements
            }
          </div>
          <div className="downloadLink" onClick={this.checkForMissing}>Download</div>

        </div>
      )
    }
    
    
  }
  view_login(){
    return (
      <React.Fragment>
        <input onChange={this.updateFormElement} type="text" value={this.state.form.email} name="email" placeholder="enter your address"/>
        <input onChange={this.updateFormElement} type="password" value={this.state.form.password} name="password" placeholder="password"/>
        <ToolTip show={this.state.hintIndex===0} index={0} closeHandler={this.updateHint}>
          <button onClick={this.performLogin} >next</button>
          <div className="hint">Click next to login</div>
        </ToolTip>
      </React.Fragment>
    )
  }
  view_upload(){
    return (
      <form onSubmit={this.handleFileUpload}>
        <h1>File upload</h1>
        <ToolTip show={this.state.hintIndex===1} index={1} closeHandler={this.updateHint}>
          <input onChange={this.selectFile} type="file" />
          <div className="hint">Choose a CSV file to upload</div>
        </ToolTip>
        
        {this.state.fileSelected ? 
          <ToolTip show={this.state.hintIndex===2} index={2} closeHandler={this.updateHint}>
            <button onClick={this.startProcessFile}>process</button> 
            <div className="hint">Click process to use this CSV file</div>
          </ToolTip>
          
          : null
        }
        {
          this.checkRights('SEEUSERS') ? <UserList token={this.state.userData.data.token}/> : null
        }
      </form>);
  }
  render(){
    let modalToDisplay = null;
    let outputData;
    switch(this.state.modalDisplay){
      case 'removal':
        modalToDisplay = (
          <Modal display={this.state.modalDisplay==='removal'} onClose={this.closeRemovalWarning}>
            <div className="downloadWarning">
              {this.createLinkedData('excluded').length-2} rows have invalid data.
              <br/>
              <button onClick={this.downloadFilteredData}>Remove invalid and continue</button>
              <br/>
              <button onClick={this.saveCurrentLinkData}>save this arrangement</button>
              <br/>
              <button onClick={this.showInvalidData}>see invalid data</button>
              <br/>
              <button onClick={this.resetToBeginning}>load different data</button>
              
            </div>
          </Modal>) 
        break;
        case 'downloadIncomplete':
          modalToDisplay = (
            <Modal display={true} onClose={this.closeModal}>
              <div className="">
                You are missing the following mandatory fields:<br/>
                {this.state.modalAdditionalContent}<br/>
                <button onClick={this.closeModal}>Return</button>
              </div>
            </Modal>)
            break;
        case 'download':
          outputData = this.createLinkedData('filtered');
          let bpd = outputData.bpd.map( subArray => {
            return `"${subArray.join(`","`)}"`
          });
          let houseHappy = outputData.houseHappy.map( subArray => {
            return `"${subArray.join(`","`)}"`
          });
          let homebot = outputData.homebot.map( subArray => {
            return `"${subArray.join(`","`)}"`
          });
          bpd = bpd.join("\n");
          houseHappy = houseHappy.join("\n");
          homebot = homebot.join("\n");
          //encodeURIComponent(JSON.stringify(
          modalToDisplay = (
            <Modal display={true} onClose={this.closeModal}>
              <div className="downloadTool">
                <a className="downloadButton"
                  href={`data:text/plain;charset=utf-8,${encodeURIComponent(bpd)}`}
                  download={`${this.state.form.email}_BPD-${this.getCurrentDateFormatted()}.csv`}
                >BPD download</a><br/>
                <a className="downloadButton"
                  href={`data:text/plain;charset=utf-8,${encodeURIComponent(houseHappy)}`}
                  download={`${this.state.form.email}_HOUSEHAPPY_VALID-${this.getCurrentDateFormatted()}.csv`}
                >HouseHappy download</a><br/>
                <a className="downloadButton"
                  href={`data:text/plain;charset=utf-8,${encodeURIComponent(homebot)}`}
                  download={`${this.state.form.email}_HOMEBOT_VALID-${this.getCurrentDateFormatted()}.csv`}
                >Homebot download</a>
              </div>
            </Modal>)
            break;
        case 'invalidDisplay':
          outputData = this.createLinkedData('excluded');
          outputData = outputData.map( subArray => {
            return `"${subArray.join(`","`)}"`
          });
          outputData = outputData.join("\n")
          modalToDisplay = (         
          <React.Fragment>
            <div className="returnToInvalidChoice" onClick={this.closeInvalidModal}>Back</div>
            <Modal display={true} onClose={this.closeInvalidModal}>
              <div className="downloadTool">
                <a className="downloadButton"
                  href={`data:text/plain;charset=utf-8,${encodeURIComponent(outputData)}`}
                  download={`${this.state.form.email}_bad_data-${this.getCurrentDateFormatted()}.csv`}
                >download</a>
              </div>
              <TableDataDisplay data={this.createLinkedData('excluded')}/>
              <div className="centerText"><button className="obviousButton" onClick={this.closeModal}>CLOSE</button></div>
            </Modal>
          </React.Fragment>)
          break;
        case 'destinationTitleConflict':
          let target = this.state.fieldList.originalSource[this.state.selectedDestinationTitle].linkedTo;
          modalToDisplay = ( 
            <Modal display={true} onClose={this.closeModal}>
              {this.state.modalAdditionalContent}
            </Modal>)
          break;  
        case 'parseAddress':
          modalToDisplay = ( 
            <Modal display={true} onClose={this.closeInvalidModal}>
              The incoming value looks like a full address.<br/>
              You you want to split it up amonst address, city, state, and zip?<br/>
              Example<br/>
              {this.state.modalAdditionalContent}
              <button onClick={()=>this.toggleAddressConversion()}>Split it up</button><button onClick={this.closeModal}>cancel</button>
            </Modal>)      
          case 'saving':
            modalToDisplay = ( 
              <Modal display={true} onClose={this.closeModal}>
                Saving link data to server...
                {this.state.modalAdditionalContent}
              </Modal>)                  
    }
    return(
      <div className="container">
        <main>{this['view_'+this.state.view]()}</main>
        { modalToDisplay }
      </div>
    )
  }
}

export default App;
