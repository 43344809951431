import React from 'react';

export default (props)=>{
    console.log(props);
    return (
        <div className={`userContainer ${props.highlight ? 'highlight' : ''}`}>
            <div className="userName">{props.data.name}</div>
            <div className="userEmail">{props.data.email}</div>
            <div className="actions">
                <button type="button" onClick={()=>{props.deleteUser(props.data.id)}}>Delete</button>
                <button type="button" onClick={props.clearUserLinkData}>clear link data</button>
            </div>
        </div>
    )
}