import React from 'react';
import './tooltip.css';

export default (props)=>{
    let displayChildren = [];
    const showHints = localStorage.showHints==='true';
    const display = showHints && props.show;
    if(!Array.isArray(props.children)){
        displayChildren = [props.children];
    } else {
        displayChildren = props.children.slice();
    }
    let hintElement = null;
    displayChildren = displayChildren.filter( item => {
        if(item.props.className!=='hint'){
            return true;
        } else {
            hintElement = item;
            return false;
        }
    })
    return (
        <div className={`tipContainer ${display?'visible':''}`}>
            <div className={`tipShadow ${display?'visible':'hidden'}`}></div>
            <div className="tipTarget">
                {displayChildren}
            </div>
            <span className={`tipHint ${display?'':'hidden'}`} onClick={(e)=>props.closeHandler(props.index)}>{hintElement}</span>
        </div>
    );
}