import React from 'react';
import './tableDataDisplay.scss';

export default (props)=>{
    debugger;
    const data = props.data.slice();
    const requiredFields = data.shift();
    return (
        <div className="tableData">
            {data.map( rowData => 
                <div className="row">
                    {rowData.map( (columnData,index) => 
                        <div className={`column ${requiredFields[index]?'required':''}`}>{columnData}</div>
                    )}
                </div>)
            }

        </div>
    );
}