import React from 'react';
import './targetField.css';

export default (props)=>{
    function handleClick(event){
        if(props.linkData && props.linkData.length>0){
            props.onClickWhileFilled(event, props.title, props.linkData);
        } else {
            props.onAddHandler(event, props.title);
        }
    }
    const titleClasses = `title ${props.required ? 'required': ''} ${(props.linkData && props.linkData.length>0) ? 'linked': ''}`
    return (<div className="targetItem" onClick={handleClick}>
        <div className={titleClasses}>{props.title}</div>
        {
            props.linkData ? <div className="link">{props.linkData.join(',')}</div> : null
        }
        <div className="data">{props.example}</div>
    </div>)
}